import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import { ButtonColor } from "../components/Button"
import Footer from "../components/Footer"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const IntroContainer = styled.div`
  margin: 60px 0;
  &.bottom {
    .introWrapper {
      margin: 0;
      text-align: left;
    }
  }
  .introWrapper {
    font-family: "Kanit", sans-serif;
    padding-right: 0;
    margin: 0 auto;
    text-align: center;
    span {
      font-weight: 300;
      font-size: 14px;
      color: #7497b2;
    }
    h2 {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 5px;
    }
    .content {
      font-weight: 400;
      font-size: 16px;
      color: #646464;
      p {
        font-weight: 400;
        font-size: 16px;
        color: #646464;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    &.bottom {
      .introWrapper {
        padding-right: 75px;
      }
    }
    .introWrapper {
      padding-right: 0;
    }
  }
  @media only screen and (min-width: 992px) {
    .introWrapper {
      margin: 0;
      text-align: left;
      padding-right: 45px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .introWrapper {
      padding-right: 50px;
    }
  }
`
const Section1Container = styled.div`
  position: relative;
  background: #7497b2;
  margin-bottom: 0;
  .imageWrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
  .contentWrapper {
    padding-top: 0;
    padding-bottom: 40px;
    font-family: "Kanit", sans-serif;
    .title {
      font-weight: 400;
      font-size: 38px;
      color: #fff;
      margin-bottom: 10px;
    }
    .text {
      font-weight: 400;
      font-size: 24px;
      color: #fff;
      margin-bottom: 20px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      color: #fff;
    }
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 80px;
    .imageWrapper {
      max-width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .contentWrapper {
      padding-top: 50px;
      padding-bottom: 100px;
      min-height: 50vh;
    }
  }
  @media only screen and (min-width: 1200px) {
    .contentWrapper {
      padding-bottom: 120px;
      min-height: 55vh;
    }
  }
`
const Section2Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  margin-bottom: 0;
  .imageWrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    order: 2;
  }
  .gb-container {
    order: 1;
  }
  .contentWrapper {
    padding-top: 40px;
    padding-bottom: 0;
    font-family: "Kanit", sans-serif;
    .title {
      font-weight: 400;
      font-size: 38px;
      color: #181818;
      margin-bottom: 10px;
    }
    .text {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 20px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      &.color-1 {
        color: #181818;
      }
      &.color-2 {
        color: #646464;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    display: block;
    margin-bottom: 80px;
    .imageWrapper {
      max-width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .contentWrapper {
      padding-top: 50px;
      padding-bottom: 100px;
      min-height: 50vh;
    }
  }
  @media only screen and (min-width: 1200px) {
    .contentWrapper {
      padding-bottom: 120px;
      min-height: 55vh;
    }
  }
`
const QueryWrapper = styled.div`
  margin: 60px 0 80px 0;
  .buttonWrapper {
    margin: 45px auto 0 auto;
    text-align: center;
  }

  @media only screen and (min-width: 768px) {
    margin: 80px 0 100px 0;
  }
`
const CardWrapper = styled.div`
  background: #fff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  height: 100%;
  min-height: 100%;
  &:hover {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
  }
  .featuredWrapper {
    display: flex;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 401px;
    a {
      display: inline-flex;
    }
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .contentWrapper {
    padding: 25px 20px;
    font-family: "Kanit", sans-serif;
    .cat {
      font-weight: 700;
      font-size: 18px;
      color: #7497B2;
      margin-bottom: 5px;
    }
    h3 {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 0;
    }
  }
`

const EnvironmentalCarePage = ({ data, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields } = data
  const {
    seoTitle,
    seoDescription,
    coverImage,
    pageTitle,
    introText,
    introContent,
    imageLeft,
    contentRight,
    contentLeft,
    imageRight,
    newsTitle,
    newsContent,
    newsPosts,
  } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)
  const getImageLeft = getImage(imageLeft)
  const getImageRight = getImage(imageRight)

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover bgImage={getCoverImage} coverText={pageTitle} />
      <IntroContainer>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <div className="introWrapper">
                <span>{introText}</span>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: pageTitle,
                  }}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: introContent.introContent,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <Section1Container>
        <div className="imageWrapper">
          <GatsbyImage image={getImageLeft} alt="" />
        </div>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} lg={12}></Col>
            <Col xs={24} lg={12}>
              <div
                className="contentWrapper"
                dangerouslySetInnerHTML={{
                  __html: contentRight.contentRight,
                }}
              />
            </Col>
          </Row>
        </div>
      </Section1Container>
      <Section2Container>
        <div className="imageWrapper">
          <GatsbyImage image={getImageRight} alt="" />
        </div>
        <div className="gb-container">
          <Row gutter={[80, 40]}>
            <Col xs={24} lg={12}>
              <div
                className="contentWrapper"
                dangerouslySetInnerHTML={{
                  __html: contentLeft.contentLeft,
                }}
              />
            </Col>
            <Col xs={24} lg={12}></Col>
          </Row>
        </div>
      </Section2Container>
      <IntroContainer className="bottom">
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <div className="introWrapper">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: newsTitle,
                  }}
                />
                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: newsContent.newsContent,
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
      </IntroContainer>
      <QueryWrapper>
        <div className="gb-container">
          <Row gutter={[16, 16]}>
            {newsPosts &&
              newsPosts.map((post, index) => {
                const getFeatured = getImage(post.featured)
                let multiCat = ""
                if (post.categories) {
                  post.categories.map(cat => {
                    multiCat += cat.name + ", "
                    return true
                  })
                }

                return (
                  <Col key={index} xs={24} sm={12} lg={8}>
                    <CardWrapper>
                      <div className="featuredWrapper">
                        <Link to={`/news/${post.slug}/`}>
                          <GatsbyImage image={getFeatured} alt="" />
                        </Link>
                      </div>
                      <div className="contentWrapper">
                        <p className="cat">
                          {" "}
                          {multiCat &&
                            multiCat.substring(0, multiCat.length - 2)}
                        </p>
                        <Link to={`/news/${post.slug}/`}>
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: post.title,
                            }}
                          />
                        </Link>
                      </div>
                    </CardWrapper>
                  </Col>
                )
              })}
          </Row>
          <Row>
            <Col span={24}>
              <div className="buttonWrapper">
                <ButtonColor
                  cta="/news/"
                  text={intl.formatMessage({
                    id: "textReadMore",
                  })}
                />
              </div>
            </Col>
          </Row>
        </div>
      </QueryWrapper>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query EnvironmentalCarePageQuery($locale: String) {
    pageFields: allContentfulEnvironmentalCarePage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
          pageTitle
          introText
          introContent {
            introContent
          }
          imageLeft {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
          }
          contentRight {
            contentRight
          }
          contentLeft {
            contentLeft
          }
          imageRight {
            gatsbyImageData(width: 1000, placeholder: BLURRED)
          }
          newsTitle
          newsContent {
            newsContent
          }
          newsPosts {
            slug
            title
            featured {
              gatsbyImageData(
                quality: 100,
                formats: [AUTO, WEBP],
                placeholder: BLURRED
              )
            }
            categories {
              name
            }
          }
        }
      }
    }
  }
`

export default injectIntl(EnvironmentalCarePage)
